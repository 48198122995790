import React from "react";
import AddURL from "./components/AddURL";
import UploadFile from "./components/UploadFile";
import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import { LightTooltip, StyledPaperContainer } from "./ProductDetails.styles";
import { capitalizeFirstLetter } from "src/utils/commonFunctions";
import { getCatalogDimensions } from "src/utils/getDimensions";

const AddGraphicImage = ({
  uploadedFile,
  deleteFile,
  type,
  urlValue,
  onURLChange,
  urlError,
  disableURL,
}) => {
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  let graphicDimensions = getCatalogDimensions(catalogDetails, "graphic");
  let notecardDimensions = getCatalogDimensions(catalogDetails, "notecard");

  const catalogItem =
    type === "graphic" ? graphicDimensions : notecardDimensions;
  const disableUpload = [50, 51, 52].includes(catalogDetails?.id) || !!urlValue;

  const imageTitle =
    type === "graphic"
      ? catalogDetails?.no_of_graphics === 1
        ? "Product"
        : catalogDetails?.no_of_graphics === 2
        ? "Pendant"
        : ""
      : type;

  return (
    <Box className="child">
      <Typography variant="h4">
        {capitalizeFirstLetter(imageTitle)} Design
      </Typography>
      {[50, 51, 52].includes(catalogDetails?.id) ? (
        <></>
      ) : (
        <Typography variant="body2" sx={{ my: 1 }}>
          <b>Standard {capitalizeFirstLetter(imageTitle)} Dimensions:</b>{" "}
          {catalogItem?.width === null ? 0 : catalogItem?.width}
          px x {catalogItem?.height === null ? 0 : catalogItem?.height}
          px.
        </Typography>
      )}

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        spacing={2}
        sx={{ width: "100%", mt: 2 }}
      >
        <LightTooltip
          title={"For Non Personalized Graphics"}
          placement="bottom-end"
        >
          <StyledPaperContainer>
            <UploadFile
              type={type}
              uploadedFile={uploadedFile}
              catalogItem={catalogItem}
              disableUpload={disableUpload}
              deleteFile={deleteFile}
            />
          </StyledPaperContainer>
        </LightTooltip>

        <Typography
          variant="subtitle2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          OR
        </Typography>
        <LightTooltip
          title={"For Personalized Graphics"}
          placement="bottom-end"
        >
          <StyledPaperContainer>
            <AddURL
              urlValue={urlValue}
              onChange={onURLChange}
              urlError={urlError}
              disabled={disableURL}
            />
          </StyledPaperContainer>
        </LightTooltip>
      </Stack>
    </Box>
  );
};

export default AddGraphicImage;

export const content = {
  companyName: "InnerCircle Prints",
  pageTitle: "Choose Your Subscription Plan",
  features: ["Free 15-day trial", "Unlimited Team Members", "Cancel Anytime"],
};

export const packages = [
  {
    id: 1,
    name: "Monthly",
    desc: "per user, per month",
    price: 14.99,
    includes: [
      "5 Social Profiles",
      "5 Scheduled Posts Per Profile",
      "400+ Templates",
      "Calendar View",
      "24/7 Support",
    ],
  },
  {
    id: 2,
    name: "Yearly",
    desc: "per user, per month",
    price: 120,
    includes: [
      "10 Social Profiles",
      "25 Scheduled Posts Per Profile",
      "400+ Templates",
      "Calendar View",
      "24/7 Support",
    ],
  },
];

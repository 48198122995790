import { setModalState } from "src/redux/actions/modalActions";
import {
  setCatalogDetails,
  setCatalogList,
} from "src/redux/actions/orderActions";
import { loadingAction, loadingBtnAction } from "src/redux/actions/userActions";
import request from "src/utils/request";
import { toastify } from "src/utils/toast";

export const getCatalogsList = async (dispatch) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get("/catalog");
    if (res) {
      dispatch(loadingAction(false));
      dispatch(setCatalogList(res.data.data.catalogs));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
    if (e.response.status === 404) {
      dispatch(setCatalogList([]));
    }
  }
};

export const getCatalogByID = async (dispatch, catalogID) => {
  dispatch(loadingAction(true));

  try {
    const res = await request.get(`catalog/${catalogID}`);
    if (res) {
      console.log(res, "catalog by id");

      dispatch(loadingAction(false));
      dispatch(setCatalogDetails(res.data.data.catalog));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
    if (e.response.status === 404) {
      dispatch(setCatalogDetails([]));
    }
  }
};

export const addCatalog = async (dispatch, data, reset) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/catalog`, data);

    if (res) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getCatalogsList(dispatch);
      reset();
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const updateCatalog = async (dispatch, data, id) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post(`/catalog/${id}?_method=PATCH`, data);

    if (res) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getCatalogsList(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const deleteCatalog = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.delete(`/catalog/${id}?_method=Delete`);

    if (res) {
      dispatch(setModalState(undefined));
      getCatalogsList(dispatch);
      toastify("success", res.data.message);
      dispatch(loadingBtnAction(false));
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingBtnAction(false));
  }
};

export const addFAQ = async (dispatch, payload, catalogID) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/catalog/faqs", payload);

    if (res) {
      dispatch(setModalState(undefined));
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      getCatalogByID(dispatch, catalogID)
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

import * as yup from "yup";

export const catalogSchema = yup.object().shape({
  title: yup.string().min(3).max(32).required("title is required"),
  description: yup.string(),
  noOfGraphics: yup.string().required("No of graphics is required"),
  price: yup.number().typeError("Phone must be a number").required(),
  shipPrice: yup.number().typeError("Phone must be a number"),
  sku: yup.string().min(3).max(32).required(),
  mockup_link: yup.string().nullable(),
  design_link: yup.string().nullable(),
  return_address: yup.string().nullable(),
});

export const faqSchema = yup.object().shape({
  question: yup.string().min(3).max(32).required("Question is required"),
  answer: yup.string().required("Answer is required"),
});

export const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#212B36" : "rgba(145, 158, 171, 0.32)",
    height: "55px",
    borderRadius: "6px",
    zIndex: 9999,
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    maxHeight: "180px",
  }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
};

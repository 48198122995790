import React from "react";
import Iconify from "../iconify/Iconify";
import ImageViewPopup from "./ImageViewPopup";
import { Box, CardMedia, IconButton } from "@mui/material";
import { isStringOrFilePath } from "src/pages/products/details/utils";

const DeleteImageViewer = ({ file, filename, deleteFile, popup }) => {
  return (
    <Box sx={{ position: "relative", width: "80px", height: "80px",}}>
      <IconButton
        onClick={deleteFile}
        sx={{
          position: "absolute",
          backgroundColor: "#919EAB",
          zIndex: 2000,
          right: -10,
          top: -10,
          "&:hover": {
            backgroundColor: "#919EAB",
          },
        }}
      >
        <Iconify
          icon="eva:close-fill"
          color="#fff"
          sx={{ width: "16px", height: "16px" }}
        />
      </IconButton>
      {popup ? (
        <ImageViewPopup
          imageSRC={
            isStringOrFilePath(file) === "String"
              ? [file]
              : [URL.createObjectURL(file)]
          }
          fileName={filename}
        />
      ) : (
        <CardMedia
          component={"img"}
          image={
            isStringOrFilePath(file) === "String"
              ? [file]
              : [URL.createObjectURL(file)]
          }
          alt={filename}
          // sx={{ width: "80px", height: "80px" }}
        />
      )}
    </Box>
  );
};

export default DeleteImageViewer;

function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

function calculateAspectRatio(width, height) {
  const divisor = gcd(width, height);
  return `${width / divisor}:${height / divisor}`;
}

export function checkDimensionsById(item, width, height) {
  const inputAspectRatio = calculateAspectRatio(width, height);
  const itemAspectRatio = calculateAspectRatio(item.width, item.height);

  // Check if the provided dimensions meet or exceed the minimum dimensions
  const meetsMinDimensions = width >= item.width && height >= item.height;

  return (
    meetsMinDimensions &&
    ((width === height && item.width === item.height) ||
      inputAspectRatio === itemAspectRatio)
  );
}

export const getImageDimension = (link) => {
  if (Array.isArray(link)) {
    return link[1]?.metadata ? link[1]?.metadata : "";
  } else {
    return link?.metadata ? link?.metadata : "";
  }
};

export function compareDimensions(uploaded, standard) {
  if (
    uploaded.width === null ||
    uploaded.height === null ||
    standard.width === null ||
    standard.height === null
  ) {
    return null;
  }

  const uploadedAspectRatio = calculateAspectRatio(
    uploaded.width,
    uploaded.height
  );
  const standardAspectRatio = calculateAspectRatio(
    standard.width,
    standard.height
  );

  const uploadedWidth = Number(uploaded.width);
  const uploadedHeight = Number(uploaded.height);
  const standardWidth = Number(standard.width);
  const standardHeight = Number(standard.height);

  if (
    (uploadedWidth === standardWidth && uploadedHeight === standardHeight) ||
    uploadedAspectRatio === standardAspectRatio
  ) {
    return true;
  } else {
    return false;
  }
}

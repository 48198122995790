import { LoadingButton } from "@mui/lab";
import {
  Alert,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { faqSchema } from "./utils";
import { addFAQ } from "src/pages/catalog/request";

const AddFAQs = ({ catalogID }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loadingButton);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(faqSchema),
  });

  const onSubmitHandler = (data) => {
    console.log(data, "data hhwehj");

    const payload = {
      catalog_id: catalogID,
      question: data?.question,
      answer: data?.answer,
    };

    addFAQ(dispatch, payload, catalogID);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
        Add a Frequently Asked Question
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing={3}>
          <TextField
            {...register("question")}
            label="Question"
            type="text"
            required
          />
          {errors.question?.message && (
            <Alert severity="error">{errors.question?.message}</Alert>
          )}

          <TextField
            {...register("answer")}
            label="Answer"
            rows={4}
            multiline
            type="text"
          />
          {errors.answer?.message && (
            <Alert severity="error">{errors.answer?.message}</Alert>
          )}
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            loading={loading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Add
          </LoadingButton>
        </Stack>
      </form>
    </>
  );
};

export default AddFAQs;

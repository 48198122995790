import request from "src/utils/request";
import { toastify } from "src/utils/toast";
import { setModalState } from "src/redux/actions/modalActions";
import {
  loadingAction,
  loadingBtnAction,
  setSubscription,
} from "src/redux/actions/userActions";
import { setPlans } from "src/redux/actions/paymentActions";

export const getPlans = async (dispatch) => {
  dispatch(loadingAction(true));
  try {
    const res = await request.get("/subscriptions");
    if (res) {
      dispatch(setPlans(res.data.data));
      dispatch(loadingAction(false));
    }
  } catch (e) {
    dispatch(loadingAction(false));
  }
};

export const addPlan = async (dispatch, payload) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/subscriptions", payload);
    if (res) {
      toastify("success", res.data.message);
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      getPlans(dispatch);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(setModalState(undefined));
    dispatch(loadingBtnAction(false));
  }
};

export const deletePlan = async (dispatch, id) => {
  dispatch(loadingBtnAction(true));

  try {
    const res = await request.delete(`/subscriptions/${id}`);
    if (res) {
      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      dispatch(setModalState(undefined));
      getPlans(dispatch);
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
    toastify("error", e.response.data.message);
  }
};

export const createSubscription = async (dispatch, payload, navigate) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.post("/subscriptions/subscribe", payload);
    if (res) {
      toastify("success", res.data.message);
      dispatch(loadingBtnAction(false));
      dispatch(setModalState(undefined));
      if (navigate) {
        navigate("/login");
      } else {
        dispatch(setSubscription(res.data.data));
      }
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(setModalState(undefined));
    dispatch(loadingBtnAction(false));
  }
};

export const cancelPlan = async (dispatch) => {
  dispatch(loadingBtnAction(true));
  try {
    const res = await request.get("/subscriptions/unsubscribe/");
    if (res) {
      console.log(res, "jkjk");

      dispatch(loadingBtnAction(false));
      toastify("success", res.data.message);
      dispatch(setModalState(undefined));
      dispatch(setSubscription(res.data.data));
    }
  } catch (e) {
    dispatch(loadingBtnAction(false));
  }
};

import React, { useEffect, useState } from "react";
import AddGraphicImage from "./AddGraphicImage";
import { Box, Card, Stack } from "@mui/material";
import {
  setGraphicURL,
  setNotecardURL,
  setPreviewImageSRC,
  setUploadedGraphicImage,
  setUploadedNotecardImage,
} from "src/redux/actions/productActions";
import { toastify } from "src/utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { handleConvert, isImageUrl, isValidURL } from "./utils";
import {
  ImageContainer,
  StyledCardContent,
  StyledCardMedia,
} from "./ProductDetails.styles";
import AddToCart from "./components/AddToCart";
import LinkToEtsy from "./components/LinkToEtsy";
import LinkToAmazon from "./components/LinkToAmazon";
import UpdateProduct from "./components/UpdateProduct";
import AddToProducts from "./components/AddToProducts";
import EditPreviewImage from "./components/EditPreviewImage";
import EditCatalogTitle from "./components/EditCatalogTitle";
import ProductDescription from "./components/ProductDescription";
import { isEmptyObject } from "src/utils/commonFunctions";
import Carousel from "react-material-ui-carousel";
import AddPreviewImage from "./AddPreviewImage";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const previewImageSRC = useSelector((state) => state.product.previewImageSRC);
  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);
  const userType = useSelector((state) => state.user.user.type);

  const [productTitle, setProductTitle] = useState("");
  const [graphicUrlError, setGraphicUrlError] = useState(false);
  const [notecardUrlError, setNotecardUrlError] = useState(false);

  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );

  const type = catalogDetails?.breadCrumb;

  const noOfGraphics =
    type === "products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const previewImageIsUploaded =
    previewImageSRC?.src && !isEmptyObject(previewImageSRC.src) ? true : false;

  const graphicImageIsUploaded =
    uploadedGraphicImage?.src && !isEmptyObject(uploadedGraphicImage?.src)
      ? true
      : false;

  const notecardImageIsUploaded =
    uploadedNotecardImage?.src && !isEmptyObject(uploadedNotecardImage?.src)
      ? true
      : false;

  useEffect(() => {
    if (type === "products") {
      dispatch(setPreviewImageSRC({}, ""));

      if (isImageUrl(catalogDetails?.uploadedData) === "url") {
        dispatch(setGraphicURL(catalogDetails?.uploadedData));
        dispatch(setUploadedGraphicImage({}, ""));
      } else {
        handleConvert(dispatch, catalogDetails?.uploadedData, "graphic");
      }

      if (noOfGraphics === 2) {
        if (isImageUrl(catalogDetails?.notecardData) === "url") {
          dispatch(setNotecardURL(catalogDetails?.notecardData));
          dispatch(setUploadedNotecardImage({}, ""));
        } else {
          handleConvert(dispatch, catalogDetails?.notecardData, "notecard");
        }
      }
    } else {
      dispatch(setUploadedGraphicImage({}, ""));
      dispatch(setUploadedNotecardImage({}, ""));
      dispatch(setGraphicURL(""));
      dispatch(setNotecardURL(""));
    }
    setProductTitle(catalogDetails?.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onUploadPreviewImage(e) {
    const file = e.target.files[0];

    if (file) {
      const tempExtention = file.name.split(".");
      const fileExtention =
        tempExtention[tempExtention.length - 1].toLowerCase();
      const allowedFileExtentions = ["png", "jpg"];

      if (!allowedFileExtentions.includes(fileExtention)) {
        toastify(
          "warning",
          "Please upload valid file type. File type allowed: PNG, JPG"
        );
        return;
      }

      if (file.size > 20000000) {
        toastify("warning", "File size should be less than 20MB");
        return;
      }

      dispatch(setPreviewImageSRC(file, "preview-image.png"));
    }
  }

  const handleUrl = (e, type) => {
    const inputUrl = e.target.value;
    if (type === "graphic") {
      dispatch(setGraphicURL(inputUrl));

      if (inputUrl.trim() === "") {
        setGraphicUrlError(false);
      } else {
        setGraphicUrlError(!isValidURL(inputUrl));
      }
    } else {
      dispatch(setNotecardURL(inputUrl));

      if (inputUrl.trim() === "") {
        setNotecardUrlError(false);
      } else {
        setNotecardUrlError(!isValidURL(inputUrl));
      }
    }
  };

  const reset = () => {
    if (type === "Catalog") {
      dispatch(setUploadedGraphicImage({}, ""));
      dispatch(setUploadedNotecardImage({}, ""));
      setProductTitle(catalogDetails.name);
      dispatch(setGraphicURL(""));
      dispatch(setNotecardURL(""));
      dispatch(setPreviewImageSRC({}, ""));
    }
  };

  const isDisabledProducts =
    noOfGraphics === 0
      ? true
      : noOfGraphics === 2
      ? (graphicImageIsUploaded || graphicURL) &&
        (notecardImageIsUploaded || notecardURL) &&
        !graphicUrlError &&
        !notecardUrlError
      : (graphicImageIsUploaded || graphicURL) && !graphicUrlError;

  const isDisabledCatalog =
    noOfGraphics === 0
      ? true
      : noOfGraphics === 2
      ? (graphicImageIsUploaded || graphicURL) &&
        (notecardImageIsUploaded || notecardURL) &&
        !graphicUrlError &&
        !notecardUrlError &&
        previewImageIsUploaded
      : (graphicImageIsUploaded || graphicURL) &&
        !graphicUrlError &&
        previewImageIsUploaded;

  return (
    <Card>
      <StyledCardContent>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          flexWrap={"wrap"}
          spacing={4}
        >
          <Box style={{ flex: 1 }}>
            <ImageContainer>
              {userType === "Seller" && type === "products" ? (
                <EditPreviewImage handlePreviewImage={onUploadPreviewImage} />
              ) : (
                ""
              )}
              {type === "products" ? (
                <StyledCardMedia
                  component="img"
                  image={
                    previewImageIsUploaded
                      ? URL.createObjectURL(previewImageSRC?.src)
                      : catalogDetails?.cover
                  }
                  alt="preview"
                />
              ) : (
                <Carousel autoPlay={false}>
                  {catalogDetails?.images?.map((image, index) => (
                    <StyledCardMedia
                      key={index}
                      component="img"
                      image={image}
                      sx={{ height: 440 }}
                      alt="preview"
                    />
                  ))}
                </Carousel>
              )}
            </ImageContainer>
          </Box>

          <Box style={{ flex: 1 }}>
            <ProductDescription />
          </Box>
        </Stack>

        {userType === "Seller" && (
          <>
            {type === "Catalog" ? (
              <AddPreviewImage
                uploadedFile={previewImageSRC}
                deleteFile={() => dispatch(setPreviewImageSRC({}, ""))}
                productTitle={productTitle}
                setProductTitle={setProductTitle}
                onUploadPreviewImage={onUploadPreviewImage}
              />
            ) : (
              <Box sx={{ mt: 4 }}>
                <EditCatalogTitle
                  productTitle={productTitle}
                  onChange={(e) => {
                    setProductTitle(e.target.value);
                  }}
                />
              </Box>
            )}

            {noOfGraphics === 0 ? (
              ""
            ) : (
              <>
                <AddGraphicImage
                  uploadedFile={uploadedGraphicImage}
                  deleteFile={() => dispatch(setUploadedGraphicImage({}, ""))}
                  type={"graphic"}
                  urlValue={graphicURL}
                  onURLChange={(e) => handleUrl(e, "graphic")}
                  urlError={graphicUrlError}
                  disableURL={!!graphicImageIsUploaded}
                />
                {noOfGraphics === 2 && (
                  <AddGraphicImage
                    uploadedFile={uploadedNotecardImage}
                    deleteFile={() =>
                      dispatch(setUploadedNotecardImage({}, ""))
                    }
                    type={"notecard"}
                    urlValue={notecardURL}
                    onURLChange={(e) => handleUrl(e, "notecard")}
                    urlError={notecardUrlError}
                    disableURL={!!notecardImageIsUploaded}
                  />
                )}
              </>
            )}

            <Stack
              direction={"row"}
              justifyContent={"flex-end"}
              flexWrap={"wrap"}
              spacing={2}
            >
              <AddToCart disabled={!isDisabledProducts} reset={reset} />
              {type === "products" ? (
                <>
                  <UpdateProduct
                    disabled={!isDisabledProducts}
                    productTitle={productTitle}
                  />
                  <LinkToEtsy
                    listingID={catalogDetails?.listing_id}
                    id={catalogDetails?.id}
                  />
                  <LinkToAmazon
                    amazonSKU={catalogDetails?.amazon_sku}
                    id={catalogDetails?.id}
                  />
                </>
              ) : (
                <AddToProducts
                  disabled={!isDisabledCatalog}
                  productTitle={productTitle}
                  reset={reset}
                />
              )}
            </Stack>
          </>
        )}
      </StyledCardContent>
    </Card>
  );
};

export default ProductDetails;

import React, { useEffect } from "react";
import { Container, Grid, Typography, Button, Stack } from "@mui/material";
import Iconify from "src/components/iconify";
import AddPlan from "src/sections/modals/subscription/AddPlans";
import { setModalState } from "src/redux/actions/modalActions";
import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "./requests";
import SubscriptionCard from "src/components/subscription-card/SubscriptionCard";
import DeleteSubscription from "src/sections/modals/subscription/DeleteSubscription";

const SubscriptionManagement = () => {
  const dispatch = useDispatch();
  const subscriptionPlans = useSelector(
    (state) => state.payment.subscriptionPlans
  );

  useEffect(() => {
    getPlans(dispatch);
  }, [dispatch]);

  return (
    <Container maxWidth="xl" sx={{ margin: "0px", padding: "0px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={"wrap"}
        sx={{ mb: 5 }}
      >
        <Typography variant="h4">Subscription Management</Typography>

        <Button
          variant={"contained"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => dispatch(setModalState(<AddPlan />))}
        >
          Add Plans
        </Button>
      </Stack>

      <Grid container spacing={3}>
        {subscriptionPlans?.map((subscription) => (
          <Grid item xs={12} sm={6} md={4} key={subscription.id}>
            <SubscriptionCard
              item={subscription}
              onDelete={() =>
                dispatch(
                  setModalState(<DeleteSubscription id={subscription?.id} />)
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SubscriptionManagement;

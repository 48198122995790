import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { createSubscription } from "src/pages/subscription-management/requests";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const cardSchema = Yup.object().shape({
  card_number: Yup.string()
    .required("Card number is required")
    .matches(/^\d{16}$/, "Card number must be 16 digits"),
  card_expiry: Yup.string()
    .required("Expiry date is required")
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Expiry date must be in MM/YY format")
    .test("expiry-date", "Expiry date must be in the future", (value) => {
      if (!value) return false;
      const [month, year] = value.split("/").map(Number);
      const currentYear = new Date().getFullYear() % 100; // Get last two digits of the year
      const currentMonth = new Date().getMonth() + 1; // Months are 0-based
      return (
        year > currentYear || (year === currentYear && month >= currentMonth)
      );
    }),
  card_cvc: Yup.string()
    .required("CVC is required")
    .matches(/^\d{3}$/, "CVC must be 3 digits"),
});

const CreateSubscription = ({ planID }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.user.loadingButton);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(cardSchema),
  });

  const onSubmit = (data) => {
    console.log("Form Submitted:", data);

    const month = data?.card_expiry?.split("/")[0];
    const year = "20" + data?.card_expiry?.split("/")[1];

    const payload = {
      plan_id: planID,
      card_number: data?.card_number,
      card_expiry_month: month,
      card_expiry_year: year,
      card_cvc: data?.card_cvc,
    };

    createSubscription(dispatch, payload, navigate);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography variant="h5">Payment Plan</Typography>
      <Typography variant="body2" color={"gray"}>
        Please add your billing information.
      </Typography>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12}>
          <Controller
            name="card_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Card Number"
                fullWidth
                error={!!errors.card_number}
                helperText={errors.card_number?.message}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="card_expiry"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Expiry Date (MM/YY)"
                fullWidth
                error={!!errors.card_expiry}
                helperText={errors.card_expiry?.message}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sx={{ mb: 2 }}>
          <Controller
            name="card_cvc"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="CVC"
                fullWidth
                error={!!errors.card_cvc}
                helperText={errors.card_cvc?.message}
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Button type="button" variant="contained" color="inherit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            loading={isLoading}
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateSubscription;

function transformOrderData(order) {
  return order?.orderDetails?.map((item) => {
    const catalog = item.catalog;
    let selectedSizeDetails = {
      price: parseFloat(catalog?.price),
      initialShip: parseFloat(catalog?.shipping_price),
      additionalShip: parseFloat(catalog?.additional_shipping_price),
    };

    if ([50, 51, 52].includes(catalog?.id)) {
      if (!item.personalization_details) {
        selectedSizeDetails = {
          price: 0,
          initialShip: 0,
          additionalShip: 0,
        };
      } else {
        const selectedSize = item.personalization_details?.find(
          (detail) => detail.formatted_name === "Size"
        )?.formatted_value;
        const sizeDetails = catalog.personalization_details?.find(
          (detail) => detail.formatted_name === "size"
        )?.formatted_value;
        const selectedSizeInfo = sizeDetails?.find(
          (size) => size.value === selectedSize
        );

        if (selectedSizeInfo) {
          selectedSizeDetails = {
            price: selectedSizeInfo.price,
            initialShip: selectedSizeInfo.ship_price,
            additionalShip: selectedSizeInfo.additional_ship_price,
          };
        }
      }
    }

    return {
      id: catalog?.id,
      name: catalog?.title,
      price: selectedSizeDetails?.price,
      initialShip: selectedSizeDetails?.initialShip,
      additionalShip: selectedSizeDetails?.additionalShip,
    };
  });
}

export function calculateTotalBulkDiscount(order) {
  const catalogs = transformOrderData(order);

  const catalogSummary = {};

  catalogs?.forEach((item) => {
    if (!catalogSummary[item.id]) {
      catalogSummary[item.id] = {
        items: [],
      };
    }
    catalogSummary[item.id].items.push(item);
  });

  let totalPrice = 0;
  let totalShipping = 0;

  Object.entries(catalogSummary).forEach(([id, data]) => {
    if (data.items.length > 0) {
      totalPrice += data.items[0].price || 0;
      totalShipping += data.items[0].initialShip || 0;

      for (let i = 1; i < data.items.length; i++) {
        totalPrice += data.items[i].price || 0;
        totalShipping += data.items[i].additionalShip || 0;
      }

      if (parseInt(id) === 5) {
        const quantity = data.items.length;
        if (quantity < 3) {
          totalShipping = 5.83 * quantity;
        } else if (quantity === 3) {
          totalShipping = 4.83 * quantity;
        } else {
          totalShipping = 3.83 * quantity;
        }
      }
    }
  });

  return totalPrice + totalShipping;
}

import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import SKUTable from "src/sections/modals/products/SKUTable";
import { useLocation } from "react-router-dom";

export default function ProductDescription() {
  const theme = useTheme();
  const location = useLocation();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);

  const desc = JSON.parse(JSON.stringify(catalogDetails?.description));
  const price = parseFloat(catalogDetails?.price).toFixed(2);

  return (
    <Box sx={{ width: "fit-content", maxWidth: "70vh", p: 0, m: 0 }}>
      <Typography component="div" variant="h3">
        {catalogDetails?.title}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          paddingTop: theme.spacing(1),
        }}
        variant="h4"
        color="text.secondary"
        component="div"
      >
        ${price}
      </Typography>

      <Typography
        variant="subtitle1"
        color="text.secondary"
        component="div"
        className="mt-3"
      >
        <div style={{ whiteSpace: "pre-line" }}>{desc}</div>
      </Typography>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6">Mockup Link</Typography>
        <Typography variant="body2">
          <a href={catalogDetails?.mockup_link}>
            {catalogDetails?.mockup_link}
          </a>
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6">Design Link</Typography>
        <Typography variant="body2">
          <a href={catalogDetails?.design_link}>
            {catalogDetails?.design_link}
          </a>
        </Typography>
      </Box>

      <Box sx={{ my: 3 }}>
        <Typography variant="h6">Return Address</Typography>
        <Typography variant="body2">
          <div style={{ whiteSpace: "pre-line" }}>
            10807 E Montgomery Drive
            <br />
            Suite 8<br />
            Spokane Valley, WA 99206
          </div>
        </Typography>
      </Box>

      {location?.pathname === "/dashboard/products" &&
      catalogDetails?.etsy_product_skus?.length ? (
        <Box sx={{ my: 4 }}>
          <SKUTable product={catalogDetails} />
        </Box>
      ) : (
        ""
      )}

      {catalogDetails?.print_provider ? (
        <Box sx={{ my: 3 }}>
          <Typography variant="h6">Print Provider Details</Typography>
          <Typography variant="body2">
            <b>Name:</b> {catalogDetails?.print_provider?.name}
          </Typography>
          {catalogDetails?.print_provider?.shipping_details && (
            <Typography variant="body2">
              <b>Ship From:</b>{" "}
              {catalogDetails?.print_provider?.shipping_details}{" "}
            </Typography>
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

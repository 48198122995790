import React, { useEffect } from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import { Typography } from "@mui/material";
import ProductsBarChart from "./ProductsBarChart";
import { sentenceCase } from "change-case";
import MeetingNotes from "./meetingNotes";
import request from "src/utils/request";
import { useDispatch, useSelector } from "react-redux";
import { toastify } from "src/utils/toast";
import Iconify from "src/components/iconify";
import { setUserStats } from "src/redux/actions/userActions";
import TaxDocument from "../taxDocument/TaxDocument";
import ListDocument from "src/components/list-items/ListDocument";
import { productStats } from "./UserConfig";
import { bytesToMB } from "src/utils/commonFunctions";
import { format } from "date-fns";
import { setModalState } from "src/redux/actions/modalActions";
import { getTaxDocumentAdmin } from "src/pages/settings/document/request";

const StatWidget = ({ title, value, card_color, icon }) => {
  return (
    <Grid item xs={12} sm={8} md={6} xl={4}>
      <Paper
        sx={{
          color: (theme) => theme.palette[card_color].contrastText,
          backgroundColor: (theme) => theme.palette[card_color].main,
          padding: "16px",
          height: "fit-content",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            sx={{ width: "65%" }}
          >
            <Paper
              sx={{
                backgroundColor: (theme) => theme.palette[card_color].main,
              }}
            >
              <img src={icon} alt="stat-icon" width={40} height={40} />
            </Paper>

            <Typography variant="h6"> {title} </Typography>
          </Stack>

          {title === "Total Products Count" || title === "Total Products Sold" ? (
            <Typography variant="h6"> {value}</Typography>
          ) : (
            <Stack direction={"column"} alignItems={"flex-start"} width={"30%"}>
              <Typography variant="h6">Etsy: {value?.etsy}</Typography>
              <Typography variant="h6">Amazon: {value?.amazon}</Typography>
            </Stack>
          )}
        </Stack>
      </Paper>
    </Grid>
  );
};

const UserStats = ({ row, avatarUrl, setShowUserStats }) => {
  const dispatch = useDispatch();
  const userStats = useSelector((state) => state.user.userStats);
  const taxDocument = useSelector((state) => state.user.taxDocument);

  useEffect(() => {
    const getUserStats = async () => {
      try {
        const res = await request.get(`/users/stats/${row.id}`);

        if (res) {
          let data = res.data.data;
          dispatch(
            setUserStats({
              userData: row,
              stats: data,
            })
          );
        }
      } catch (e) {
        toastify("error", e.response.data.message);
      }
    };

    getUserStats();
    getTaxDocumentAdmin(dispatch, row.id);
  }, [dispatch, row]);

  const getValue = (userStats, key) => {
    const stats = userStats?.stats?.catalog_wise_counts_etsy_amazon[key];
    return stats ? { etsy: stats.etsy || 0, amazon: stats.amazon || 0 } : 0;
  };

  return (
    <Container sx={{ my: 3 }}>
      <Stack direction={"row"} alignItems={"center"} alignContent={"center"}>
        <IconButton onClick={() => setShowUserStats([])}>
          <Iconify icon="ph:arrow-left-fill" />
        </IconButton>
        <Typography variant="h4">User Stats</Typography>
      </Stack>
      <Grid container spacing={3} direction={"row"} mt={2}>
        <Grid item xs={4}>
          {" "}
          <Stack direction={"column"} sx={{ m: 2 }} spacing={2}>
            <Avatar
              alt={"user_avatar"}
              src={avatarUrl}
              sx={{ width: 120, height: 120 }}
            />
            <Stack direction={"column"} spacing={1}>
              <Typography variant="h5">{userStats?.userData?.name}</Typography>
              <Typography variant="subtitle2">
                Email: {userStats?.userData?.email}
              </Typography>
              <Typography variant="subtitle2">
                Role: {userStats?.userData?.role}
              </Typography>
              <Typography variant="subtitle2">
                Status:{" "}
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color:
                      userStats?.userData?.status === "Active"
                        ? "#229A16"
                        : "#b72136",
                    backgroundColor:
                      userStats?.userData?.status === "Active"
                        ? "rgba(84, 214, 44, 0.16)"
                        : "rgba(255, 72, 66, 0.16)",
                  }}
                >
                  {userStats
                    ? sentenceCase(
                        userStats?.userData?.status
                          ? userStats?.userData?.status
                          : ""
                      )
                    : ""}
                </Button>
              </Typography>

              {taxDocument && taxDocument?.length ? (
                <ListDocument
                  sx={{ width: "100%" }}
                  title={"Resellers Tax Permit"}
                  desc={`${bytesToMB(taxDocument[0]?.file_size)} MB
                    - ${format(
                      new Date(taxDocument[0]?.created_at),
                      "dd MMM yyyy h:mm a"
                    )}
                    `}
                  onClickItem={() =>
                    dispatch(
                      setModalState(
                        <TaxDocument fileURL={taxDocument[0]?.document} />,
                        true
                      )
                    )
                  }
                />
              ) : (
                <Alert severity="warning">
                  They have not uploaded their Resellers Tax Permit
                </Alert>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <ProductsBarChart stats={userStats?.stats} />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={2}>
        {productStats?.map(({ title, key, icon }) => (
          <StatWidget
            key={key}
            title={title}
            value={
              key === "total_products_count"
                ? userStats?.stats?.total_products_count || 0
                : key === "total_items_sold"
                ? userStats?.stats?.total_items_sold || 0
                : getValue(userStats, key)
            }
            card_color="card_one"
            icon={icon}
          />
        ))}
      </Grid>

      <Box sx={{ my: 3 }}></Box>
      <MeetingNotes userID={row.id} />
    </Container>
  );
};

export default UserStats;

import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ResizeGraphic from "./ResizeGraphic";
import { getCatalogDimensions } from "src/utils/getDimensions";

const UploadGraphics = ({ item }) => {
  const [selected, setSelected] = useState("graphic_image");

  const handleSelected = (event) => {
    setSelected(event.target.value);
  };

  let graphicDimensions = getCatalogDimensions(item, "graphic");
  let notecardDimensions = getCatalogDimensions(item, "notecard");

  const catalogItem =
    selected === "notecard_image" ? notecardDimensions : graphicDimensions;

  return item?.catalog?.no_of_graphics === 1 ? (
    <>
      <ResizeGraphic
        item={item}
        selected={"graphic_image"}
        place={"orders"}
        catalogItem={catalogItem}
      />
    </>
  ) : (
    <Box sx={{ m: 2 }}>
      <Typography variant="h4" sx={{ mb: 1.5 }}>
        Upload Graphics:
      </Typography>
      <FormControl>
        <RadioGroup
          defaultValue="pendant"
          name="radio-buttons-group"
          value={selected}
          onChange={handleSelected}
        >
          <FormControlLabel
            value="graphic_image"
            control={<Radio />}
            label="Pendant"
          />
          <FormControlLabel
            value="notecard_image"
            control={<Radio />}
            label="Notecard"
          />
        </RadioGroup>
      </FormControl>

      <Box sx={{ mt: 2 }}>
        <ResizeGraphic
          item={item}
          selected={selected}
          dualMode
          place={"orders"}
          catalogItem={catalogItem}
        />
      </Box>
    </Box>
  );
};

export default UploadGraphics;

import request from "src/utils/request";
import ApproveOrder from "../../sections/modals/order/approveOrder";
import { toastify } from "src/utils/toast";
import { fToNow } from "src/utils/formatTime";
import { Paper, styled } from "@mui/material";
import { loadingAction } from "src/redux/actions/userActions";
import { setModalState } from "src/redux/actions/modalActions";
import { getUploadedImage } from "src/sections/modals/order/utils";
import {
  getPersonalizationArray,
  productAttributes,
} from "src/sections/modals/order/variations/utils";
import {
  calculateTotalBulkDiscount,
  calculateTotalCost,
  calculateTotalSum,
  getDiscountedPrice,
} from "src/utils/bulkDiscountFunctions";

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

export const TABLE_HEAD = [
  { label: "Order ID #", id: "etsy_order_id" },
  { label: "Customer Name", id: "name" },
  { label: "Total Items", id: "total_items" },
  { label: "Amount", id: "amount" },
  { label: "Order Date", id: "date" },
  { label: "Approval Date", id: "approval_date" },
];

export const formatTableData = (row, userType) => {
  const {
    id,
    etsy_order_id,
    amazon_order_id,
    user_name,
    name,
    date,
    approval_date,
    total_items,
    tracking_id,
  } = row;

  let calculatedAmount = row ? calculateAmount(row) : 0;

  const TABLE_DATA = [
    {
      label: "Order ID #",
      id: "etsy_order_id",
      value: etsy_order_id ? (
        <a
          href={`https://www.etsy.com/your/orders/sold/new?order_id=${etsy_order_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {etsy_order_id}
        </a>
      ) : amazon_order_id ? (
        amazon_order_id
      ) : (
        id
      ),
    },
    { label: "Customer Name", id: "name", value: name },
    { label: "Seller Name", id: "user_name", value: user_name },
    {
      label: "Total Items",
      id: "total_items",
      value: total_items,
    },
    {
      label: "Amount",
      id: "amount",
      value: `$${parseFloat(calculatedAmount).toFixed(2)}`,
    },

    { label: "Order Date", id: "date", value: fToNow(date) },
    {
      label: "Approval Date",
      id: "approval_date",
      value: approval_date ?? "-",
    },
    {
      label: "Tracking ID",
      id: "tracking_id",
      value: tracking_id ? tracking_id : "-",
    },
  ];

  let tableData =
    userType === "Seller"
      ? TABLE_DATA.filter((item) => item.id !== "user_name")
      : TABLE_DATA;

  return tableData;
};

const checkimageURL = (url) => {
  if (typeof url === "string") {
    const canvaUrl = url.search("canva");
    const kittlUrl = url.search("kittl");
    const adobeUrl = url.search("adobe");

    if (canvaUrl > 0 || kittlUrl > 0 || adobeUrl > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    const canvaUrl = url[0].search("canva");
    const kittlUrl = url[0].search("kittl");
    const adobeUrl = url[0].search("adobe");

    if (canvaUrl > 0 || kittlUrl > 0 || adobeUrl > 0) {
      return true;
    } else {
      return false;
    }
  }
};

export const calculateAmount = (order) => {
  let additionalUnits = order?.orderDetails?.length - 1;
  let additionalShippingCost = additionalUnits * 2.5;

  let discounted_amount = order ? calculateTotalBulkDiscount(order) : 0.0;

  let personalization_details = order.orderDetails?.map(
    (item) => item?.personalization_details
  );

  const variationTotal = personalization_details
    ?.map((array) => calculateVariationsAmount(array))
    .reduce((acc, val) => acc + val, 0);

  let amount = discounted_amount + variationTotal;

  let subTotal =
    order?.priorty_mail_shipping === 1
      ? parseFloat(amount) + 5.99 + additionalShippingCost
      : parseFloat(amount);

  return subTotal ? parseFloat(subTotal).toFixed(2) : 0.0;
};

export function calculateVariationsAmount(array) {
  const pricePerInitial = 1.89;
  const pricePerBirthstone = 0.89;
  let total = 0;

  array?.forEach((item) => {
    if (item.formatted_name === "Initials") {
      total += item.formatted_value.length * pricePerInitial;
    }
    if (item.formatted_name === "Birthstones") {
      total += item.formatted_value.length * pricePerBirthstone;
    }
  });

  return total;
}

const validatePersonalization = (object) => {
  const sizeArray = getPersonalizationArray(object, "size");
  const colorsArray = getPersonalizationArray(object, "color");
  let personalizationDetails = object.personalization_details || [];

  let hasSize = false;
  let hasColor = false;

  personalizationDetails.forEach((detail) => {
    if (detail.formatted_name === "Size" && detail.formatted_value) {
      hasSize = true;
    }
    if (detail.formatted_name === "Color" && detail.formatted_value) {
      hasColor = true;
    }
  });

  // Validate size
  if (sizeArray?.length && !hasSize) {
    return true;
  }

  // Validate color
  if (colorsArray?.length && !hasColor) {
    return true;
  }

  return false;
};

export const handleOnHoldOrder = (
  order,
  selectedOrder,
  setSelected,
  userType,
  dispatch
) => {
  let containsURL;
  let payload = [];
  let totalAmount = "";
  let totalOrders = 0;
  let addressIncomplete;
  let variationNotUpdated;

  if (Object.keys(order).length === 0 && selectedOrder.length > 0) {
    let finalised_graphics = selectedOrder?.map((order) =>
      order?.orderDetails?.map((item) => getUploadedImage(item))
    );
    let checkIfURL = finalised_graphics[0]?.map((imageURL) =>
      imageURL?.map((url) => checkimageURL(url))
    );

    containsURL = checkIfURL?.some((subArr) => subArr.includes(true));

    let shippingAddress = selectedOrder?.map((order) =>
      order?.orderDetails[0]?.shipping_details
        ? JSON.parse(order?.orderDetails[0]?.shipping_details)
        : {}
    );
    let checkAddresses = shippingAddress?.map((address) =>
      address.billToName || address.shipToName || address.street1 ? false : true
    );

    addressIncomplete = checkAddresses.includes(true);

    let checkIfVariationNotUpdated = selectedOrder?.map((order) =>
      order?.orderDetails?.map((item) => validatePersonalization(item))
    );

    variationNotUpdated = checkIfVariationNotUpdated.some((subArr) =>
      subArr.includes(true)
    );

    totalOrders = selectedOrder.length;

    let allAmounts = selectedOrder?.map((order) => calculateAmount(order));

    totalAmount = allAmounts
      ? allAmounts
          ?.map(Number)
          .reduce((partialSum, a) => partialSum + a, 0)
          .toFixed(2)
      : 0;

    //bulk payload
    if (userType === "Seller") {
      payload = selectedOrder?.map((order) => ({
        order_id: order.id,
        amount: calculateAmount(order),
      }));
    } else if (userType === "PrintProvider") {
      payload = selectedOrder?.map((order) => ({
        order_id: order.id,
        order_item_ids: order?.orderDetails?.map((order) => order.id),
      }));
    } else {
      payload = selectedOrder?.map((order) => ({
        order_id: order.id,
        order_status: "awaiting_shipment",
      }));
    }
  } else {
    let shippingAddress = order?.orderDetails[0]?.shipping_details
      ? JSON.parse(order?.orderDetails[0]?.shipping_details)
      : {};

    totalOrders = 1;
    totalAmount = calculateAmount(order);

    let checkIfVariationNotUpdated = order?.orderDetails?.map((item) =>
      validatePersonalization(item)
    );

    variationNotUpdated = checkIfVariationNotUpdated.some((item) => item);

    let finalised_graphics = order?.orderDetails?.map((item) =>
      getUploadedImage(item)
    );

    let checkIfURL = finalised_graphics[0]?.map((imageURL) =>
      checkimageURL(imageURL)
    );

    containsURL = checkIfURL?.some((item) => item);

    if (
      shippingAddress.billToName ||
      shippingAddress.shipToName ||
      shippingAddress.street1
    ) {
      addressIncomplete = false;
    } else {
      addressIncomplete = true;
    }

    //single payload
    if (userType === "Seller") {
      payload = [
        {
          order_id: order.id,
          amount: totalAmount,
        },
      ];
    } else if (userType === "PrintProvider") {
      payload = [
        {
          order_id: order.id,
          order_item_ids: order?.orderDetails?.map((order) => order.id),
        },
      ];
    } else {
      payload = [
        {
          order_id: order.id,
          order_status: "awaiting_shipment",
        },
      ];
    }
  }

  if (userType === "Seller") {
    if (variationNotUpdated) {
      toastify("warning", "Please update variations");
    } else if (containsURL) {
      toastify("warning", "Please upload Graphic Images");
    } else if (addressIncomplete) {
      toastify("warning", "Please complete shipping address");
    } else {
      dispatch(
        setModalState(
          <ApproveOrder
            amount={totalAmount}
            totalOrders={totalOrders}
            payload={payload}
            setSelected={setSelected}
          />
        )
      );
    }
  } else {
    dispatch(
      setModalState(
        <ApproveOrder
          amount={totalAmount}
          totalOrders={totalOrders}
          payload={payload}
          setSelected={setSelected}
        />
      )
    );
  }
};

export const handleImagesToDownload = async (
  order,
  selectedOrder,
  dispatch
) => {
  var orderIDs;
  dispatch(loadingAction(true));

  if (Object.keys(order).length === 0 && selectedOrder.length > 0) {
    orderIDs = selectedOrder?.map((order) => order.id);
  } else if (Object.keys(order).length) {
    orderIDs = [order.id];
  }

  const payload = {
    order_ids: orderIDs,
  };

  try {
    const res = await request.post(`/orders/bulk/download`, payload);

    if (res) {
      const url = res.data.data[1];
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FileName.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      dispatch(loadingAction(false));
      toastify("success", res.data.message);
    }
  } catch (e) {
    toastify("error", e.response.data.message);
    dispatch(loadingAction(false));
  }
};

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const getDimensions = (personalizationDetails, item, dimensionType) => {
  const sizeDetails =
    personalizationDetails?.find((detail) => detail.formatted_name === "size")
      ?.formatted_value?.[0] || null;

  if (sizeDetails && sizeDetails[dimensionType]) {
    const dimensions = sizeDetails[dimensionType].reduce((acc, dim) => {
      acc[dim.label.toLowerCase()] = parseInt(dim.value);
      return acc;
    }, {});

    return {
      id: item?.id || null,
      name: item?.title || null,
      width: dimensions.width || null,
      height: dimensions.height || null,
    };
  }

  return null;
};

export const getDimensionsForNew = (
  catalogVariations,
  orderVariations,
  dimensionType
) => {
  const selectedSize = orderVariations?.find(
    (item) => item.formatted_name.toLowerCase() === "size"
  )?.formatted_value;

  if (!selectedSize) return null;

  const sizeData = catalogVariations?.personalization_details?.find(
    (detail) => detail.formatted_name.toLowerCase() === "size"
  );

  if (sizeData) {
    const matchingSize = sizeData.formatted_value.find(
      (size) => size.value === selectedSize
    );

    if (matchingSize && matchingSize[dimensionType]) {
      const dimensions = matchingSize[dimensionType].reduce((acc, dim) => {
        acc[dim.label.toLowerCase()] = parseInt(dim.value);
        return acc;
      }, {});

      return {
        id: catalogVariations?.id || null,
        name: catalogVariations?.title || null,
        width: dimensions.width || null,
        height: dimensions.height || null,
      };
    }
  }

  return null;
};

export function getCatalogDimensions(item, type) {
  let graphicDimensions = {};
  let notecardDimensions = {};

  if ([50, 51, 52].includes(item?.catalog?.id)) {
    graphicDimensions = getDimensionsForNew(
      item?.catalog,
      item?.personalization_details,
      "graphic_dimensions"
    );
    notecardDimensions = getDimensionsForNew(
      item?.catalog,
      item?.personalization_details,
      "notecard_dimensions"
    );
  } else {
    graphicDimensions = getDimensions(
      item?.catalog?.personalization_details,
      item?.catalog,
      "graphic_dimensions"
    );
    notecardDimensions = getDimensions(
      item?.catalog?.personalization_details,
      item?.catalog,
      "notecard_dimensions"
    );
  }

  return type === "graphic" ? graphicDimensions : notecardDimensions;
}

import {
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import CreateSubscription from "src/sections/modals/subscription/CreateSubscription";

const PriceCard = ({ id, name, desc, price }) => {
  const dispatch = useDispatch();
  const darkCard = name === "Business";

  const featuresArray = desc
    .split(".")
    .map((s) => s.trim())
    .filter(Boolean)
    .concat(Array(5).fill(""))
    .slice(0, 5);

  return (
    <>
      <Card
        sx={{
          p: 3,
          borderRadius: "10px",
          color: darkCard ? "#02B2FE" : "#2C3140",
          bgcolor: darkCard ? "#2C3140" : "#fff",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack
            direction="column"
            alignItems="center"
            className="break-words"
            spacing={1}
          >
            <Typography variant="h3" sx={{ fontSize: "1.5rem" }}>
              {name}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: "4rem", fontWeight: "700" }}
            >
              ${price}
            </Typography>

            <List>
              <Divider sx={{ width: 1 }} />
              {featuresArray?.map((feature, index) => (
                <ListItem
                  key={index}
                  sx={{
                    px: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">{feature}</Typography>
                </ListItem>
              ))}
            </List>
            <Button
              variant={"contained"}
              onClick={() =>
                dispatch(setModalState(<CreateSubscription planID={id} />))
              }
              fullWidth
            >
              Buy Now
            </Button>
          </Stack>
        </Stack>
      </Card>
    </>
  );
};

export default PriceCard;

import React, { useEffect, useMemo, useState } from "react";
import { content, packages } from "./data";
import { Container, Stack, Typography } from "@mui/material";
import PriceCard from "./PriceCard";
import DiscountBadge from "./DiscountBadge";
import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "src/pages/subscription-management/requests";

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const subscriptionPlans = useSelector(
    (state) => state.payment.subscriptionPlans
  );

  useEffect(() => {
    getPlans(dispatch);
  }, [dispatch]);

  return (
    <Container maxWidth="md" sx={{ py: 8, height: "100vh" }}>
      <Stack
        direction="column"
        justifyContent={"center"}
        spacing={5}
        sx={{ height: "100%" }}
      >
        <div>
          <Typography
            variant="h4"
            sx={{ fontSize: "3rem", fontWeight: 900, textAlign: "center" }}
          >
            {content?.pageTitle}
          </Typography>
          <Typography variant="subtitle1" align="center" sx={{ my: 2 }}>
            Stay updated with our latest news and offers! Join our growing
            community today.
          </Typography>
        </div>

        <Stack
          justifyContent={"center"}
          direction={{ xs: "column", sm: "row" }}
          spacing={4}
          position="relative"
        >
          {subscriptionPlans?.map((item) => {
            return (
              <>
                {item?.interval === "year" && <DiscountBadge />}
                <PriceCard
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  desc={item.description}
                  price={item.price}
                />
              </>
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};

export default SubscriptionPage;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import AddFAQs from "src/sections/modals/catalog/addFAQs";

const FAQs = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const catalogDetails = useSelector((state) => state.orders.catalogDetails);

  console.log(catalogDetails, "catalogDetails faqs");

  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography component="div" variant="h5" mb={3}>
          Frequently Asked Questions
        </Typography>
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              dispatch(
                setModalState(<AddFAQs catalogID={catalogDetails?.id} />)
              )
            }
          >
            + Add
          </Button>
        </div>
      </Stack>
      {catalogDetails?.faqs?.map((item) => (
        <Accordion
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            "&:not(:last-child)": {
              borderBottom: 0,
            },
            "&::before": {
              display: "none",
            },
            "& .MuiAccordionSummary-root": {
              // backgroundColor: 'rgba(0, 0, 0, .03)',
              // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            },
          }}
          // expanded={expanded === item?.id}
          // onChange={handleChange(item?.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography component="span">{item?.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{item?.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQs;

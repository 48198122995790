import jewelry from "../../assets/icons/Necklace.svg";
import keychain from "../../assets/icons/keychain.svg";
import bracelet from "../../assets/icons/bracelet.svg";
import tumblers from "../../assets/icons/tumblers.svg";
import ornaments from "../../assets/icons/ornaments.svg";
import products from "../../assets/icons/total_products.svg";
import onesie from "../../assets/icons/onesie.svg";
import initials from "../../assets/icons/initials.svg";
import birthstones from "../../assets/icons/birthstones.svg";
import frostedcup from "../../assets/icons/frostedcup.svg";
import shirt from "../../assets/icons/shirt.svg";

export const productData = [
  {
    id: 1,
    title: "Total Products",
    icon: products,
    total: (stats) => ({
      sold: stats?.sold_products_count,
      active: stats?.total_products_count,
    }),
  },
  {
    id: 6,
    title: "Ornaments",
    icon: ornaments,
    total: (stats) => ({
      "etsy Sold": stats?.ornaments_sold?.etsy_sold,
      "amazon Sold": stats?.ornaments_sold?.amazon_sold,
      "ICP Sold": stats?.ornaments_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[1]?.total
        : 0,
    }),
  },
  {
    id: 5,
    title: "Tumblers",
    icon: tumblers,
    total: (stats) => ({
      "etsy Sold": stats?.tumblers_sold?.etsy_sold,
      "amazon Sold": stats?.tumblers_sold?.amazon_sold,
      "ICP Sold": stats?.tumblers_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[0]?.total
        : 0,
    }),
  },
  {
    id: 40,
    title: "Frosted Cups",
    icon: frostedcup,
    total: (stats) => ({
      "etsy Sold": stats?.frosted_cup_sold?.etsy_sold,
      "amazon Sold": stats?.frosted_cup_sold?.amazon_sold,
      "ICP Sold": stats?.frosted_cup_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[12]?.total
        : 0,
    }),
  },
  {
    id: 27,
    title: "Pendant Necklace with Notecard",
    icon: jewelry,
    total: (stats) => ({
      "etsy Sold": stats?.jewellry_sold?.etsy_sold,
      "amazon Sold": stats?.jewellry_sold?.amazon_sold,
      "ICP Sold": stats?.jewellry_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[2]?.total
        : 0,
    }),
  },
  {
    id: 38,
    title: "Pendant Bracelet with Notecard",
    icon: bracelet,
    total: (stats) => ({
      "etsy Sold": stats?.bracelet_sold?.etsy_sold,
      "amazon Sold": stats?.bracelet_sold?.amazon_sold,
      "ICP Sold": stats?.bracelet_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[10]?.total
        : 0,
    }),
  },
  {
    id: 39,
    title: "Pendant Keychain with Notecard",
    icon: keychain,
    total: (stats) => ({
      "etsy Sold": stats?.keychain_sold?.etsy_sold,
      "amazon Sold": stats?.keychain_sold?.amazon_sold,
      "ICP Sold": stats?.keychain_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[11]?.total
        : 0,
    }),
  },
  {
    id: 45,
    title: "Initials",
    icon: initials,
    total: (stats) => ({
      "etsy Sold": stats?.initials_sold?.etsy_sold,
      "amazon Sold": stats?.initials_sold?.amazon_sold,
      "ICP Sold": stats?.initials_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[16]?.total
        : 0,
    }),
  },

  {
    id: 46,
    title: "Birthstones",
    icon: birthstones,
    total: (stats) => ({
      "etsy Sold": stats?.birthstones_sold?.etsy_sold,
      "amazon Sold": stats?.birthstones_sold?.amazon_sold,
      "ICP Sold": stats?.birthstones_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[17]?.total
        : 0,
    }),
  },
  {
    id: 47,
    title: "Gerber Organic Cotton Onesies",
    icon: onesie,
    total: (stats) => ({
      "etsy Sold": stats?.onesies_sold?.etsy_sold,
      "amazon Sold": stats?.onesies_sold?.amazon_sold,
      "ICP Sold": stats?.onesies_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[18]?.total
        : 0,
    }),
  },
  {
    id: 48,
    title: "Organic Cotton Toddler Shirt",
    icon: shirt,
    total: (stats) => ({
      "etsy Sold": stats?.toddler_shirt_sold?.etsy_sold,
      "amazon Sold": stats?.toddler_shirt_sold?.amazon_sold,
      "ICP Sold": stats?.toddler_shirt_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[19]?.total
        : 0,
    }),
  },
  {
    id: 49,
    title: "Organic Cotton Youth Shirt",
    icon: shirt,
    total: (stats) => ({
      "etsy Sold": stats?.youth_shirt_sold?.etsy_sold,
      "amazon Sold": stats?.youth_shirt_sold?.amazon_sold,
      "ICP Sold": stats?.youth_shirt_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[20]?.total
        : 0,
    }),
  },
  {
    id: 50,
    title: "Wooden Sign with Optional Easel",
    icon: products,
    total: (stats) => ({
      "etsy Sold": stats?.wooden_sign_sold?.etsy_sold,
      "amazon Sold": stats?.wooden_sign_sold?.amazon_sold,
      "ICP Sold": stats?.wooden_sign_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[22]?.total
        : 0,
    }),
  },
  {
    id: 51,
    title: "Wooden Home Decor Sign",
    icon: products,
    total: (stats) => ({
      "etsy Sold": stats?.wooden_home_decor_sold?.etsy_sold,
      "amazon Sold": stats?.wooden_home_decor_sold?.amazon_sold,
      "ICP Sold": stats?.wooden_home_decor_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[23]?.total
        : 0,
    }),
  },
  {
    id: 52,
    title: "Mug",
    icon: products,
    total: (stats) => ({
      "etsy Sold": stats?.mugs_sold?.etsy_sold,
      "amazon Sold": stats?.mugs_sold?.amazon_sold,
      "ICP Sold": stats?.mugs_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[24]?.total
        : 0,
    }),
  },
];

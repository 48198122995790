import { React, useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Alert,
  CircularProgress,
  Typography,
  FormControl,
} from "@mui/material";
import { catalogSchema, selectStyles } from "./utils";
import { useForm } from "react-hook-form";
import { toastify } from "src/utils/toast";
import { useDropzone } from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { StyledDropzone } from "../updates/CreateUpdate";
import { addCatalog, updateCatalog } from "src/pages/catalog/request";
import { StyledLoadingButton } from "src/pages/auth/login/Login.styles";
import Select from "react-select";
import LibraryImg from "../../../assets/images/library.png";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";

export default function AddCatalogForm({ buttonTitle, product }) {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectPrintprovider, setSelectPrintprovider] = useState([]);

  const userType = useSelector((state) => state.user.user.type);
  const loading = useSelector((state) => state.user.loadingButton);
  const printProviders = useSelector((state) => state.user.printProviders);

  function mapUserData(data) {
    return data?.map((item) => ({
      id: item.id,
      label: item.name,
      value: item.uuid,
    }));
  }

  async function createFile() {
    let response = await fetch(product.cover);
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "file.jpg", metadata);
    setImages([file]);
  }

  // useEffect(() => {
  //   if (product) {
  //     createFile();
  //   }
  // }, [product]);

  useEffect(() => {
    if (printProviders) {
      let options = mapUserData(printProviders);
      setOptions(options);
    }
  }, [printProviders]);

  const onDrop = (acceptedFiles) => {
    setImages(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg"],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: product ? product.name : "",
      description: product ? product.desc : "",
      noOfGraphics: product ? product.no_of_graphics : "",
      price: product ? product.price : "",
      shipPrice: product ? product.shipPrice : "",
      sku: product ? product.sku : "",
      mockup_link: product ? product.mockup_link : "",
      design_link: product ? product.design_link : "",
      return_address:
        "10807 E Montgomery Drive Suite 8 Spokane Valley, WA 99206",
    },
    resolver: yupResolver(catalogSchema),
  });

  const onSubmitHandler = (data) => {
    let formData = new FormData();

    formData.append("title", data.title);
    formData.append("handle", `${data.title}-handle`);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("no_of_graphics", data.noOfGraphics);
    formData.append("shipping_price", data.shipPrice);
    formData.append("sku", data.sku);
    formData.append("compare_price", 0);
    formData.append("quantity", 999);
    formData.append("mockup_link", data.mockup_link);
    formData.append("design_link", data.design_link);
    formData.append("return_address", data.return_address);

    if (selectPrintprovider) {
      formData.append("print_provider_id", selectPrintprovider?.id);
    }

    if (images?.length) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images[]", images[i]);
      }
    }
    if (images.length > 0) {
      if (product) {
        updateCatalog(dispatch, formData, product.id);
      } else {
        addCatalog(dispatch, formData, reset);
      }
    } else {
      toastify("warning", "Please upload a file");
    }
  };

  function removeFile(identifier) {
    setImages((prevImages) => {
      // Remove by index
      if (identifier >= 0 && identifier < prevImages.length) {
        return prevImages.filter((_, index) => index !== identifier);
      } else {
        console.error("Index out of bounds");
        return prevImages;
      }
    });
  }

  const handleChange = (event) => {
    setSelectPrintprovider(event);
  };

  console.log(product, "product");

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
        {product ? "Update" : "Add"} Product
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing={3}>
          <TextField
            {...register("title")}
            label="Title"
            type="text"
            required
          />
          {errors.title?.message && (
            <Alert severity="error">{errors.title?.message}</Alert>
          )}

          <TextField
            {...register("description")}
            label="Description"
            rows={3}
            multiline
            type="text"
          />
          {errors.description?.message && (
            <Alert severity="error">{errors.description?.message}</Alert>
          )}

          <TextField
            {...register("noOfGraphics")}
            label="No. of Graphics"
            type="text"
            required
          />
          {errors.noOfGraphics?.message && (
            <Alert severity="error">{errors.noOfGraphics?.message}</Alert>
          )}

          <TextField
            {...register("price")}
            label="Price"
            type="text"
            required
          />
          {errors.price?.message && (
            <Alert severity="error">{errors.pricing?.message}</Alert>
          )}

          <TextField
            {...register("shipPrice")}
            label="Shipping Price"
            type="text"
          />
          {errors.shipPrice?.message && (
            <Alert severity="error">{errors.shipPrice?.message}</Alert>
          )}

          <TextField {...register("sku")} label="SKU" type="number" />
          {errors.sku?.message && (
            <Alert severity="error">{errors.sku?.message}</Alert>
          )}

          <TextField
            {...register("return_address")}
            label="Return Address"
            rows={3}
            defaultValue={
              "10807 E Montgomery Drive Suite 8 Spokane Valley, WA 99206"
            }
            multiline
            type="text"
          />
          {errors.return_address?.message && (
            <Alert severity="error">{errors.return_address?.message}</Alert>
          )}

          <TextField {...register("mockup_link")} label="Mockup Link" />
          <TextField {...register("design_link")} label="Design Link" />
        </Stack>

        <Typography variant="subtitle1" sx={{ mb: 2, mt: 4 }}>
          Select Print Provider (optional)
        </Typography>

        <FormControl sx={{ width: "100%" }}>
          <Select
            options={options}
            onChange={handleChange}
            placeholder="Select..."
            isClearable
            styles={selectStyles}
          />
        </FormControl>

        <Typography variant="subtitle1" sx={{ mb: 2, mt: 4 }}>
          Add Preview Images:
        </Typography>
        <StyledDropzone elevation={3} {...getRootProps()}>
          <input {...getInputProps()} multiple />
          <img src={LibraryImg} alt="library" style={{ width: 180 }} />
          <div>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Drag Your Files Here
            </Typography>
          </div>
        </StyledDropzone>

        <Stack direction={"row"} spacing={3} flexWrap={"wrap"} mt={3}>
          {images?.map((file, index) => {
            return (
              <DeleteImageViewer
                file={file}
                filename={file.name}
                deleteFile={() => removeFile(index)}
              />
            );
          })}
        </Stack>

        <StyledLoadingButton
          sx={{ marginTop: "30px" }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
        >
          {product ? "Update" : "Create"}
        </StyledLoadingButton>
      </form>
    </>
  );
}

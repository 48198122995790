import * as React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";
import SKUTable from "src/sections/modals/products/SKUTable";

export default function ProductDescription() {
  const theme = useTheme();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);

  const type = catalogDetails?.breadCrumb;
  const desc = JSON.parse(JSON.stringify(catalogDetails.desc));
  const price = parseFloat(catalogDetails?.price).toFixed(2);
  const shipPrice = parseFloat(catalogDetails?.shipPrice).toFixed(2);

  return (
    <Box sx={{ width: "fit-content", p: 0, m: 0 }}>
      <Typography component="div" variant="h3">
        {catalogDetails?.name}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          paddingTop: theme.spacing(2),
        }}
        variant="h4"
        color="text.secondary"
        component="div"
      >
        Price ${price}
      </Typography>
      {catalogDetails?.shipPrice && (
        <Typography
          sx={{
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(2),
          }}
          variant="h4"
          color="text.secondary"
          component="div"
        >
          Shipping ${shipPrice}
        </Typography>
      )}

      <Typography
        variant="subtitle1"
        color="text.secondary"
        component="div"
        className="mt-3"
      >
        <div style={{ whiteSpace: "pre-line" }}>{desc}</div>
      </Typography>

      {type === "products" && catalogDetails?.etsy_product_skus?.length ? (
        <Box sx={{ my: 4 }}>
          <SKUTable product={catalogDetails} />
        </Box>
      ) : (
        ""
      )}

      {catalogDetails?.print_provider ? (
        <Box sx={{ my: 3 }}>
          <Typography variant="h6">Print Provider Details</Typography>
          <Typography variant="body2">
            <b>Name:</b> {catalogDetails?.print_provider?.name}
          </Typography>
          <Typography variant="body2">
            <b>Ship From:</b> {catalogDetails?.print_provider?.shipping_details}{" "}
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

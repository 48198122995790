import { Box, Typography } from "@mui/material";
import React from "react";

const DiscountBadge = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-30px",
        right: { xs: "20%", sm: "10%" },
        textAlign: "center",
        zIndex: 10,
      }}
    >
      <Typography
        sx={{
          fontFamily: "'Dancing Script', cursive",
          transform: "rotate(25deg)",
          fontSize: "1.5rem",
          fontWeight: 600,
          color: "#1465FA",
        }}
      >
        Save 33%
      </Typography>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_123_4)">
          <rect width="28" height="28" />
          <path
            d="M2 26C10 24.8211 26 18.3705 26 2"
            stroke="#1465FA"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_123_4">
            <rect width="28" height="28" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};

export default DiscountBadge;

export const catalogsShipping = [
  {
    catalogID: 6,
    name: "ornament",
    shipping_detail: [
      {
        method: "Standard",
        time: "2 - 5 business days",
        firstItem: "$4.20",
        additionalItem: "$1.99",
      },
      {
        method: "Priority",
        time: "2 - 5 business days",
        firstItem: "$" + parseFloat(4.2 + 5.99).toFixed(2),
        additionalItem: "$" + parseFloat(1.99 + 2.50).toFixed(2),
      },
    ],
  },
  {
    catalogID: 40,
    name: "Frosted Cup",
    shipping_detail: [
      {
        method: "Standard",
        time: "2 - 5 business days",
        firstItem: "$5.95",
        additionalItem: "$3.75",
      },
      {
        method: "Priority",
        time: "2 - 5 business days",
        firstItem: "$" + parseFloat(5.95 + 5.99).toFixed(2),
        additionalItem: "$" + parseFloat(3.75 + 2.50).toFixed(2),
      },
    ],
  },
  {
    catalogID: 5,
    name: "tumbler",
    shipping_detail: [
      {
        method: "Standard",
        time: "2 - 5 business days",
        quantity: "<3",
        per_unit: "$5.83",
      },
      {
        method: "Standard",
        time: "2 - 5 business days",
        quantity: "3",
        per_unit: "$4.83",
      },
      {
        method: "Standard",
        time: "2 - 5 business days",
        quantity: ">3",
        per_unit: "$3.83",
      },
      {
        method: "Priority",
        time: "2 - 5 business days",
        quantity: "<3",
        per_unit: "$9.82 <br /> (3.83 + 5.99)",
      },
    ],
  },
];

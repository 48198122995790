import React, { useRef } from "react";
import { LightTooltip, StyledPaperContainer } from "./ProductDetails.styles";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";
import DeleteImageViewer from "src/components/image-viewer/DeleteImageViewer";
import EditCatalogTitle from "./components/EditCatalogTitle";
import { isEmptyObject } from "src/utils/commonFunctions";

const AddPreviewImage = ({
  uploadedFile,
  productTitle,
  setProductTitle,
  deleteFile,
  onUploadPreviewImage,
}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fileIsUploaded =
    uploadedFile?.src && !isEmptyObject(uploadedFile?.src) ? true : false;

  return (
    <Box className="child">
      <Typography variant="h4">Product Thumbnail</Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        spacing={2}
        sx={{ width: "100%", mt: 2 }}
      >
        <LightTooltip
          title={"Product Thumbnail is required"}
          placement="bottom-end"
        >
          <StyledPaperContainer>
            {fileIsUploaded ? (
              <DeleteImageViewer
                file={uploadedFile?.src}
                fileName={uploadedFile?.fileName}
                deleteFile={deleteFile}
                popup
              />
            ) : (
              <>
                <Button
                  sx={{
                    width: "100%",
                    height: "100%",
                    color: "#000",
                    "&:hover": {
                      backgroundColor: "#f3f3f3",
                    },
                  }}
                  startIcon={<Image />}
                  onClick={handleButtonClick}
                >
                  Upload Preview Image
                </Button>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  type="file"
                  hidden
                  onChange={onUploadPreviewImage}
                />
              </>
            )}
          </StyledPaperContainer>
        </LightTooltip>
        <StyledPaperContainer>
          <EditCatalogTitle
            productTitle={productTitle}
            onChange={(e) => {
              setProductTitle(e.target.value);
            }}
          />
        </StyledPaperContainer>
      </Stack>
    </Box>
  );
};

export default AddPreviewImage;

import React, { useEffect, useState } from "react";
import Iconify from "../../components/iconify/Iconify";
import AddCatalogForm from "src/sections/modals/catalog/addCatalog";
import { Container, Stack, Typography, Box, Button, Grid } from "@mui/material";
import { ProductList } from "../../sections/dashboard/products";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "src/redux/actions/modalActions";
import { getCatalogsList } from "./request";
import { getEtsyShop } from "../stores/request";
import { setPreviewImageSRC } from "src/redux/actions/productActions";
import {
  loadingBtn2Action,
  loadingBtnAction,
} from "src/redux/actions/userActions";

export default function CatalogPage() {
  const dispatch = useDispatch();
  const catalogList = useSelector((state) => state.orders.catalogList);
  const userType = useSelector((state) => state.user.user.type);

  const [message, setMessage] = useState("");
  const [catalog, setCatalog] = useState([]);

  useEffect(() => {
    getCatalogsList(dispatch);
    dispatch(setModalState(undefined));
    if (userType === "Seller") {
      getEtsyShop(dispatch);
    }
    dispatch(setPreviewImageSRC({}, ""));
    dispatch(loadingBtnAction(false));
    dispatch(loadingBtn2Action(false));
  }, [dispatch, userType]);

  useEffect(() => {
    if (catalogList?.length) {
      setCatalog(catalogList);
    } else {
      setMessage("There are no products in the catalog.");
    }
  }, [catalogList]);

  return (
    <Container maxWidth="2xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={4}
      >
        <Typography variant="h4">Catalog</Typography>
        {userType === "Super Admin" && (
          <Button
            variant={"contained"}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => dispatch(setModalState(<AddCatalogForm />, true))}
          >
            Add Product
          </Button>
        )}
      </Stack>
      {catalog.length ? (
        <ProductList products={catalog} />
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "50vh" }}
        >
          <Grid item xs={3}>
            {message}
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  Button,
  CircularProgress,
} from "@mui/material";
import Iconify from "../iconify";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";

const getFeatures = (desc) =>
  desc
    .split(".")
    .map((s) => s.trim())
    .filter(Boolean)
    .slice(0, 5);

const SubscriptionCard = ({
  item,
  status,
  onDelete,
  onCancel,
  resubscribe,
}) => {
  let features = getFeatures(item?.description);
  const isLoading = useSelector((state) => state.user.loadingButton);
  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardContent>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h6" gutterBottom>
            {item?.name}
          </Typography>
          {onDelete && (
            <IconButton color="error" onClick={onDelete}>
              <Iconify icon={"eva:trash-2-outline"} />
            </IconButton>
          )}
          {status === "success" ? (
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={onCancel}
              style={{
                borderRadius: "20px",
                padding: "4px 12px",
              }}
            >
              Cancel
            </Button>
          ) : status === "canceled" ? (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={resubscribe}
              style={{
                borderRadius: "20px",
                padding: "4px 12px",
              }}
              loading={isLoading}
              loadingIndicator={<CircularProgress color="inherit" size={16} />}
            >
              Resubscribe
            </LoadingButton>
          ) : (
            <></>
          )}
        </Stack>

        <Typography variant="h6" color="primary" gutterBottom>
          ${item?.price}/month
        </Typography>

        <List>
          {features?.map((feature, index) => (
            <ListItem
              key={index}
              sx={{
                px: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{feature}</Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;

import React from "react";
import request from "src/utils/request";
import { useDispatch, useSelector } from "react-redux";
import StyledButton from "src/components/button/StyledButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { loadingBtn2Action } from "src/redux/actions/userActions";
import {
  setGraphicURL,
  setNotecardURL,
  setUploadedGraphicImage,
  setUploadedNotecardImage,
} from "src/redux/actions/productActions";
import { toastify } from "src/utils/toast";
import { isImageUrl } from "../utils";
import { isEmptyObject } from "src/utils/commonFunctions";
import { useLocation } from "react-router-dom";

const UpdateProduct = ({ disabled, productTitle }) => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const previewImageSRC = useSelector((state) => state.product.previewImageSRC);
  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);
  const UpdateProductLoading = useSelector(
    (state) => state.user.loadingButton2
  );

  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );
  const location = useLocation();
  const noOfGraphics =
    location?.pathname === "/dashboard/products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const previewImageIsUploaded =
    previewImageSRC?.src && !isEmptyObject(previewImageSRC.src) ? true : false;

  const updateProduct = async () => {
    dispatch(loadingBtn2Action(true));

    let formData = new FormData();
    formData.append("product_id", catalogDetails.id);
    formData.append("catalog_id", catalogDetails.catalog.id);
    formData.append("quantity", "1");
    formData.append("title", productTitle);
    formData.append("price", catalogDetails.price);
    formData.append("description", catalogDetails.description);

    previewImageIsUploaded &&
      formData.append("preview_image", previewImageSRC?.src);

    if (graphicURL) {
      formData.append("url", graphicURL);
    } else {
      formData.append("graphic_image", uploadedGraphicImage?.src);
    }

    if (noOfGraphics === 2) {
      if (notecardURL) {
        formData.append("notecard_url", notecardURL);
      } else {
        formData.append("notecard_image", uploadedNotecardImage?.src);
      }
    }

    try {
      const res = await request.post(`/products/update`, formData);

      if (res) {
        dispatch(loadingBtn2Action(false));
        let response = res.data.data;

        if (isImageUrl(response?.graphic_image) === "url") {
          dispatch(setGraphicURL(response?.graphic_image));
          dispatch(setUploadedGraphicImage({}, ""));
        } else {
          dispatch(setUploadedGraphicImage(response?.graphic_image, ""));
        }

        if (noOfGraphics === 2) {
          if (isImageUrl(response?.notecard_image) === "url") {
            dispatch(setNotecardURL(response?.notecard_image));
            dispatch(setUploadedNotecardImage({}, ""));
          } else {
            dispatch(setUploadedNotecardImage(response?.notecard_image, ""));
          }
        }

        toastify("success", res.data.message);
      }
    } catch (e) {
      dispatch(loadingBtn2Action(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <StyledButton
      title={"Update Products"}
      onClick={updateProduct}
      disabled={disabled}
      loading={UpdateProductLoading}
      startIcon={<CheckCircleIcon />}
    />
  );
};

export default UpdateProduct;

import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { catalogsShipping } from "./shippingConfig";

const ShippingCost = () => {
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const catalog = catalogsShipping?.filter(
    (item) => item?.catalogID === catalogDetails?.id
  )[0];

  console.log(catalog, "catalog");

  return (
    <Box sx={{ width: "fit-content", p: 0, m: 0 }}>
      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shipping method</TableCell>
              <TableCell>Delivery time</TableCell>

              <TableCell>
                {catalog?.catalogID === 5 ? "Quantity" : "First item"}
              </TableCell>
              <TableCell>
                {catalog?.catalogID === 5 ? "Per Unit" : "Additional item"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {catalog ? (
              catalog?.shipping_detail?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.method}</TableCell>
                  <TableCell sx={{ width: "30%" }}>{row.time}</TableCell>

                  <TableCell sx={{ width: "30%" }}>
                    {catalog?.catalogID === 5 ? row.quantity : row.firstItem}
                  </TableCell>
                  <TableCell sx={{ width: "30%" }}>
                    {catalog?.catalogID === 5
                      ? row.per_unit
                      : row.additionalItem}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No Information available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShippingCost;

import React from "react";
import { Alert, Typography } from "@mui/material";
import {
  compareDimensions,
  getImageDimension,
} from "src/sections/modals/order/dimensions";
import { getCatalogDimensions } from "src/utils/getDimensions";

const Validations = ({ item }) => {
  let graphicDimensions = getCatalogDimensions(item, "graphic");
  let notecardDimensions = getCatalogDimensions(item, "notecard");

  const graphicImageDimensions = item.graphic_image
    ? getImageDimension(item?.graphic_image)
    : "";

  const notecardImageDimension = item?.notecard_image
    ? getImageDimension(item?.notecard_image)
    : "";

  let graphicValidator = item.graphic_image
    ? compareDimensions(graphicImageDimensions, graphicDimensions)
    : "";

  let notecardValidator = item?.notecard_image
    ? compareDimensions(notecardImageDimension, notecardDimensions)
    : "";
  return (
    <div>
      {" "}
      {graphicDimensions ? (
        <Typography variant="body2">
          <b>Standard Graphic Dimensions:</b>
          <br />
          {graphicDimensions?.width === null ? 0 : graphicDimensions?.width}
          px x{" "}
          {graphicDimensions?.height === null ? 0 : graphicDimensions?.height}
          px.
        </Typography>
      ) : (
        <Typography variant="body2">Please choose variations first</Typography>
      )}
      {graphicImageDimensions?.width != null &&
        graphicImageDimensions?.height != null && (
          <>
            <Typography variant="body2" mt={1}>
              <b>Graphic Dimensions:</b> <br />
              {`${graphicImageDimensions.width}px x ${graphicImageDimensions.height}px`}
            </Typography>

            {graphicValidator ? (
              <Alert severity="success">Correct</Alert>
            ) : (
              <Alert severity="error">Wrong</Alert>
            )}
          </>
        )}
      {item?.notecard_image && (
        <>
          <Typography variant="body2">
            <b>Standard Notecard Dimensions:</b>
            <br />
            {notecardDimensions?.width === null ? 0 : notecardDimensions?.width}
            px x{" "}
            {notecardDimensions?.height === null
              ? 0
              : notecardDimensions?.height}
            px.
          </Typography>

          {notecardImageDimension?.width != null &&
            notecardImageDimension?.height != null && (
              <>
                <Typography variant="body2" mt={1}>
                  <b>Notecard Dimensions:</b> <br />{" "}
                  {notecardImageDimension?.width === null ||
                  notecardImageDimension?.height === null
                    ? "NA"
                    : `${notecardImageDimension?.width}px x ${notecardImageDimension?.height}px`}
                </Typography>
                {notecardImageDimension?.width === null ||
                notecardImageDimension?.height === null ? (
                  ""
                ) : notecardValidator ? (
                  <Alert severity="success">Correct</Alert>
                ) : (
                  <Alert severity="error">Wrong</Alert>
                )}
              </>
            )}
        </>
      )}
    </div>
  );
};

export default Validations;

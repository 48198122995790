export const loginAction =
  (token, id, name, email, image, user_type, subscription) => (dispatch) => {
    dispatch({
      type: "Login",
      payload: {
        token,
        id,
        name,
        email,
        image,
        user_type,
      },
    });
  };

export const logoutAction = () => async (dispatch) => {
  dispatch({
    type: "Logout",
  });
};

export const setAuthorized = (authCheck) => async (dispatch) => {
  dispatch({
    type: "isAuthorized",
    payload: authCheck,
  });
};

export const setSubscription = (data) => async (dispatch) => {
  dispatch({
    type: "setSubscription",
    payload: data,
  });
};

export const loadingAction = (isLoading) => (dispatch) => {
  if (isLoading) {
    dispatch({
      type: "LoadingOn",
    });
  } else {
    dispatch({
      type: "LoadingOff",
    });
  }
};

export const loadingBtnAction = (isLoading) => (dispatch) => {
  if (isLoading) {
    dispatch({
      type: "LoadingBtnOn",
    });
  } else {
    dispatch({
      type: "LoadingBtnOff",
    });
  }
};

export const loadingBtn2Action = (isLoading) => (dispatch) => {
  if (isLoading) {
    dispatch({
      type: "LoadingBtn2On",
    });
  } else {
    dispatch({
      type: "LoadingBtn2Off",
    });
  }
};

export const loadingSkeletonAction = (isLoading) => (dispatch) => {
  if (isLoading) {
    dispatch({
      type: "LoadingSkeletonnOn",
    });
  } else {
    dispatch({
      type: "LoadingSkeletonOff",
    });
  }
};

export const setUserEmail = (email) => async (dispatch) => {
  dispatch({
    type: "resetPassword",
    payload: email,
  });
};

export const setActiveStep = (step) => async (dispatch) => {
  dispatch({
    type: "activeStep",
    payload: step,
  });
};

export const getUsers = (boolean) => async (dispatch) => {
  dispatch({
    type: "getUsers",
    payload: boolean,
  });
};

export const setEtsyShopName = (shopName) => async (dispatch) => {
  dispatch({
    type: "setEtsyShopName",
    payload: shopName,
  });
};

export const setUserStats = (stats) => async (dispatch) => {
  dispatch({
    type: "setUserStats",
    payload: stats,
  });
};

export const setUsersMeta =
  (activeUsers, inActiveUsers, meta, count) => async (dispatch) => {
    dispatch({
      type: "setUserMeta",
      payload: { activeUsers, inActiveUsers, meta, count },
    });
  };

export const setUserList = (users) => async (dispatch) => {
  dispatch({
    type: "setUserList",
    payload: users,
  });
};

export const setUserSearched = (boolean) => async (dispatch) => {
  dispatch({
    type: "setSearched",
    payload: boolean,
  });
};

export const setAllUserStats = (stats) => async (dispatch) => {
  dispatch({
    type: "setAllUserStats",
    payload: stats,
  });
};

export const setSingleUserStat = (stats) => async (dispatch) => {
  dispatch({
    type: "setSingleUserStat",
    payload: stats,
  });
};

export const setUserPagination = (page, rowsPerPage) => async (dispatch) => {
  dispatch({
    type: "setUserPagination",
    payload: { page, rowsPerPage },
  });
};

export const setSelectedFilter = (filter) => async (dispatch) => {
  dispatch({
    type: "setSelectedFilter",
    payload: filter,
  });
};

export const setPrintProviders = (printproviders) => async (dispatch) => {
  dispatch({
    type: "setPrintProviders",
    payload: printproviders,
  });
};

export const setTaxDocument = (document) => async (dispatch) => {
  dispatch({
    type: "setTaxDocument",
    payload: document,
  });
};

export const setNotificationIsSeen = (boolean) => async (dispatch) => {
  dispatch({
    type: "setNotificationIsSeen",
    payload: boolean,
  });
};

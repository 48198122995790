import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubscriptionCard from "src/components/subscription-card/SubscriptionCard";
import { createSubscription } from "src/pages/subscription-management/requests";
import { setModalState } from "src/redux/actions/modalActions";
import CancelSubscription from "src/sections/modals/subscription/CancelSubscription";

const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.user.subscription);
  const payload = {
    plan_id: subscription?.plan?.id,
  };
  console.log(subscription, "subscription");

  useEffect(() => {
console.log(subscription, 'subscription');

  },[subscription])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <SubscriptionCard
            item={subscription?.plan}
            status={subscription?.status}
            onCancel={() => dispatch(setModalState(<CancelSubscription />))}
            resubscribe={() => createSubscription(dispatch, payload)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SubscriptionPlan;

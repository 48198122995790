import React, { useState } from "react";
import loader from "../../../assets/loader.gif";
import correct from "../../../assets/correct.gif";
import Iconify from "src/components/iconify/Iconify";
import FilerobotImageEditor, { TABS } from "react-filerobot-image-editor";
import { toastify } from "src/utils/toast";
import { useDropzone } from "react-dropzone";
import { base64ToFile, saveImage } from "./utils";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "src/pages/orders/requests/icp/updateOrder";
import { checkDimensionsById } from "src/sections/modals/order/dimensions";
import {
  setUploadedGraphicImage,
  setUploadedNotecardImage,
} from "src/redux/actions/productActions";
import { setModalState } from "src/redux/actions/modalActions";
import libraryImg from "../../../assets/images/library.png";

const ResizeGraphic = ({ item, selected, dualMode, place, catalogItem }) => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.user.type);
  const [image, setImage] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dimensionsValid, setDimensionsValid] = useState(false);

  const [uploadedImageDimensions, setUploadedImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const checkAndUploadGraphic = (file, width, height) => {
    setLoading(true);
    const currentID = catalogItem?.id;

    setUploadedImageDimensions({ width: width, height: height });
    const result = checkDimensionsById(catalogItem, width, height, 0.1);

    if (result) {
      setDimensionsValid(true);
      setTimeout(() => {
        if (place === "graphic") {
          dispatch(setUploadedGraphicImage(file, file.name));
          dispatch(setModalState(undefined));
        } else if (place === "notecard") {
          dispatch(setUploadedNotecardImage(file, file.name));
          dispatch(setModalState(undefined));
        } else {
          updateOrder(file, item?.id, dispatch, userType, selected);
        }
        setLoading(false);
      }, 5000);
    } else {
      setDimensionsValid(false);
      setTimeout(() => {
        toastify(
          "error",
          "The dimensions are wrong. Please resize and upload correct dimensions"
        );
        setLoading(false);
      }, 5000);
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setFileUploaded(true);
      setImage(event.target.result);
      const img = new Image();
      img.onload = () => {
        checkAndUploadGraphic(file, img.width, img.height);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
    },
  });

  const resizeImage = (imageBase64, width, height, type) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const resizedImageBase64 = canvas.toDataURL("image/png");
      if (type === "save") {
        saveImage(resizedImageBase64);
      } else if (type === "upload") {
        let file = base64ToFile(resizedImageBase64, "mockup.png");
        checkAndUploadGraphic(file, width, height);
      }
    };

    img.src = imageBase64;
  };

  return (
    <div>
      {!fileUploaded ? (
        <>
          <div
            {...getRootProps()}
            style={{
              height: dualMode ? "30vh" : "52vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "2px dashed #cccccc",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            <img src={libraryImg} alt="library" style={{ width: 120 }} />
            <p>Drag 'n' drop a file here, or click to select one</p>
          </div>
          <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              The standard dimensions for the{" "}
              {catalogItem
                ? `${catalogItem?.name} are ${catalogItem?.width}px x ${catalogItem?.height}px`
                : "Unknown ID"}
            </Alert>
            <Alert severity="info">
              Please upload the graphic images with a white background.
            </Alert>
          </Box>
        </>
      ) : loading ? (
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
          sx={{ height: dualMode ? "40vh" : "70vh" }}
        >
          <img
            src={loader}
            alt="loader"
            style={{ width: "160px", height: "160px" }}
          />
          <Typography variant="subtitle1">
            Please wait while we check for correct dimensions
            <div className="loading-dots">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </Typography>
        </Stack>
      ) : !loading ? (
        dimensionsValid ? (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            sx={{ height: dualMode ? "40vh" : "70vh" }}
          >
            <img
              src={correct}
              alt="loader"
              style={{ width: "160px", height: "160px" }}
            />
            <Typography variant="h4">The dimensions are correct.</Typography>
            <Typography variant="bosy2" color={"GrayText"}>
              Please wait while your image is getting uploaded
              <div className="loading-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </Typography>
          </Stack>
        ) : (
          <>
            <div
              style={{
                height: "54vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box>
                <FilerobotImageEditor
                  source={image}
                  moreSaveOptions={[
                    {
                      label: "Save as png",
                      onClick: (triggerSaveModal, triggerSave) =>
                        triggerSave((...args) => {
                          resizeImage(
                            args[1].imgSrc,
                            args[0].width,
                            args[0].height,
                            "save"
                          );
                        }),
                      icon: () => <Iconify icon="ic:baseline-save-alt" />,
                    },
                    {
                      label: "Upload graphic",
                      onClick: (triggerSaveModal, triggerSave) =>
                        triggerSave((...args) => {
                          resizeImage(
                            args[1].imgSrc,
                            args[0].width,
                            args[0].height,
                            "upload"
                          );
                        }),
                      icon: () => <Iconify icon="material-symbols:upload" />,
                    },
                  ]}
                  closeOnLoad={true}
                  tabsIds={[TABS.RESIZE]}
                  defaultTabId={TABS.RESIZE}
                  saveAs="base64"
                />
              </Box>
            </div>

            <Box sx={{ mt: 1 }}>
              <Alert severity="info">
                Please upload the graphic images with a white background.
              </Alert>
              <Box sx={{ mb: 1 }}></Box>
              <Alert severity="info">
                The standard dimensions for the{" "}
                {catalogItem
                  ? `${catalogItem?.name} are ${catalogItem?.width}px x ${catalogItem?.height}px`
                  : "Unknown ID"}
              </Alert>
              <Box sx={{ mb: 1 }}></Box>
              <Alert severity="info">
                The uploaded image dimensions is{" "}
                {uploadedImageDimensions?.width === null
                  ? 0
                  : uploadedImageDimensions?.width}
                px x
                {uploadedImageDimensions?.height === null
                  ? 0
                  : uploadedImageDimensions?.height}
                px.
              </Alert>
            </Box>
          </>
        )
      ) : null}
    </div>
  );
};

export default ResizeGraphic;

import { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography, Container } from "@mui/material";
import ProductDetails from "src/pages/products/details/ProductDetails";
import CartWidget from "src/sections/dashboard/products/ProductCartWidget";

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  textTransform: "capitalize",
  color: "#000",
}));

export default function ProductPage() {
  const canvasRef = useRef(null);
  const location = useLocation()
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const userType = useSelector((state) => state.user.user.type);
  const breadCrumb = location?.pathname?.split("/")[2]

  useEffect(() => {
    canvasRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Container maxWidth="2xl">
      <div ref={canvasRef}></div>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledLink
          underline="hover"
          color="inherit"
          to={`/dashboard/${breadCrumb}`}
        >
          {breadCrumb}
        </StyledLink>
        <Typography color="text.primary">{catalogDetails.title}</Typography>
      </Breadcrumbs>

      <Typography variant="h4" sx={{ mb: 2 }}>
        Product Details
      </Typography>
      {userType === "Seller" && <CartWidget />}
      <ProductDetails />
    </Container>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledButton from "src/components/button/StyledButton";
import AddIcon from "@mui/icons-material/Add";
import { loadingBtn2Action } from "src/redux/actions/userActions";
import { toastify } from "src/utils/toast";
import request from "src/utils/request";
import { useLocation } from "react-router-dom";

const AddToProducts = ({ disabled, productTitle, reset }) => {
  const dispatch = useDispatch();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const AddProductLoading = useSelector((state) => state.user.loadingButton2);
  const previewImageSRC = useSelector((state) => state.product.previewImageSRC);
  const graphicURL = useSelector((state) => state.product.graphicURL);
  const notecardURL = useSelector((state) => state.product.notecardURL);
  const uploadedGraphicImage = useSelector(
    (state) => state.product.uploadedGraphicImage
  );
  const uploadedNotecardImage = useSelector(
    (state) => state.product.uploadedNotecardImage
  );
  const location = useLocation();
  const noOfGraphics =
    location?.pathname === "/dashboard/products"
      ? catalogDetails?.catalog?.no_of_graphics
      : catalogDetails.no_of_graphics;

  const addToProducts = async () => {
    dispatch(loadingBtn2Action(true));

    let formData = new FormData();
    formData.append("catalog_id", catalogDetails.id);
    formData.append("quantity", "1");
    formData.append("title", productTitle);
    formData.append("price", catalogDetails.price);
    formData.append("description", catalogDetails.description);
    formData.append("preview_image", previewImageSRC?.src);

    if (graphicURL) {
      formData.append("url", graphicURL);
    } else {
      formData.append("graphic_image", uploadedGraphicImage?.src);
    }

    if (noOfGraphics === 2) {
      if (notecardURL) {
        formData.append("notecard_url", notecardURL);
      } else {
        formData.append("notecard_image", uploadedNotecardImage?.src);
      }
    }

    try {
      const res = await request.post(`/products`, formData);

      if (res) {
        reset();
        toastify("success", res.data.message);
        dispatch(loadingBtn2Action(false));
      }
    } catch (e) {
      dispatch(loadingBtn2Action(false));
      toastify("error", e.response.data.message);
    }
  };

  return (
    <StyledButton
      title={"Add to Products"}
      disabled={disabled}
      onClick={addToProducts}
      loading={AddProductLoading}
      startIcon={<AddIcon />}
    />
  );
};

export default AddToProducts;

export function getDefaultArray(item, sizeArray, colorsArray) {
  let defaultArray = [];

  defaultArray.push({
    property_id: 54,
    value_id: 3,
    formatted_name: "Personalization",
    formatted_value: "",
  });

  if (sizeArray?.length > 0) {
    defaultArray.push({
      property_id: 506,
      value_id: 2,
      formatted_name: "Size",
      formatted_value: sizeArray[0]?.value,
    });
  }

  if (sizeArray?.length > 0 && sizeArray[0]?.sku) {
    defaultArray.push({
      property_id: 508,
      value_id: 7,
      formatted_name: "sku",
      formatted_value: colorsArray?.length
        ? sizeArray[0]?.sku + colorsArray[0]?.sku
        : sizeArray[0]?.sku,
    });
  }

  if (colorsArray?.length > 0) {
    defaultArray.push({
      property_id: 500,
      value_id: 1,
      formatted_name: "Color",
      formatted_value: colorsArray[0]?.value,
    });
  }

  if (item?.no_of_graphics === 2) {
    defaultArray.push({
      property_id: 504,
      value_id: 4,
      formatted_name: "Birthstones",
      formatted_value: [],
    });
  }

  return defaultArray;
}

export function fillFormattedValues(
  defaultArray,
  color,
  size,
  personalization,
  birthstone,
  sizeArray,
  colorsArray
) {
  const selectedSizeObj = sizeArray?.find((item) => item.value === size);
  const selectedColorObj = colorsArray?.find((item) => item.value === color);

  defaultArray.forEach((variation) => {
    if (variation.formatted_name === "Color") {
      variation.formatted_value = color;
    }

    if (variation.formatted_name === "Size") {
      variation.formatted_value = size;
    }

    if (variation.formatted_name === "Personalization") {
      variation.formatted_value = personalization;
    }

    if (variation.formatted_name === "Birthstones") {
      variation.formatted_value = birthstone;
    }

    if (variation.formatted_name === "sku") {
      variation.formatted_value = selectedSizeObj?.sku
        ? selectedSizeObj.sku + (selectedColorObj?.sku || "")
        : "";
    }
  });

  return defaultArray;
}

export const getPersonalizationArray = (item, attributeName) => {
  if (
    !item?.catalog?.personalization_details ||
    !Array.isArray(item.catalog.personalization_details)
  ) {
    return null;
  }

  const attribute = item.catalog.personalization_details.find(
    (detail) => detail?.formatted_name === attributeName
  );

  return attribute?.formatted_value || null;
};

import React from "react";
import AddURL from "./components/AddURL";
import UploadFile from "./components/UploadFile";
import { useSelector } from "react-redux";
import { dimensionsArray } from "src/sections/modals/order/dimensions";
import { Box, Stack, Typography } from "@mui/material";
import { LightTooltip, StyledPaperContainer } from "./ProductDetails.styles";
import { capitalizeFirstLetter } from "src/utils/commonFunctions";
import { useLocation } from "react-router-dom";

const AddGraphicImage = ({
  uploadedFile,
  deleteFile,
  type,
  urlValue,
  onURLChange,
  urlError,
  disableUpload,
  disableURL,
}) => {
  const location = useLocation();
  const catalogDetails = useSelector((state) => state.orders.catalogDetails);
  const catalog =
    location?.pathname === "/dashboard/products"
      ? catalogDetails?.catalog
      : catalogDetails;

  let graphicItem = dimensionsArray.find((d) => d.id === catalog?.id);
  let notecardItem = dimensionsArray.find((d) => d.id === 111);

  const catalogItem = type === "graphic" ? graphicItem : notecardItem;

  return (
    <Box className="child">
      <Typography variant="h4">{capitalizeFirstLetter(type)} Image</Typography>
      <Typography variant="body2" sx={{ my: 1 }}>
        <b>Standard {type} Dimensions:</b>{" "}
        {catalogItem?.width === null ? 0 : catalogItem?.width}
        px x {catalogItem?.height === null ? 0 : catalogItem?.height}
        px.
      </Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        spacing={2}
        sx={{ width: "100%", mt: 2 }}
      >
        <LightTooltip
          title={"For Non Personalized Graphics"}
          placement="bottom-end"
        >
          <StyledPaperContainer>
            <UploadFile
              type={type}
              uploadedFile={uploadedFile}
              catalogItem={catalogItem}
              disableUpload={disableUpload}
              deleteFile={deleteFile}
            />
          </StyledPaperContainer>
        </LightTooltip>

        <Typography
          variant="subtitle2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          OR
        </Typography>
        <LightTooltip
          title={"For Personalized Graphics"}
          placement="bottom-end"
        >
          <StyledPaperContainer>
            <AddURL
              urlValue={urlValue}
              onChange={onURLChange}
              urlError={urlError}
              disabled={disableURL}
            />
          </StyledPaperContainer>
        </LightTooltip>
      </Stack>
    </Box>
  );
};

export default AddGraphicImage;
